import { List, ListItem } from 'konsta/react'
import React, { useEffect, useState } from 'react'
import { MdAddHome, MdDoorFront, MdLock, MdPlayArrow } from 'react-icons/md'
import { Device, DeviceCategoryType } from '../../../types/Ezlo/Device'
import { ToggleLabel } from '../../../components/ToggleLabel/ToggleLable'
import { useHubSocketContext } from '../../../providers/hubsocket'
import { DoorLockEnum } from '../../../constants/ezlo.constant'

interface Props {
  devices?: Device[]
}

const Icons = {
  [DeviceCategoryType.SIREN]: MdDoorFront,
  [DeviceCategoryType.DOOR_LOCK]: MdLock
}
export function DeviceList({ devices }: Props) {
  const { lockProperties, lockUnlockDoor } = useHubSocketContext()
  const [lock, unlock] = useState<boolean>(false)

  useEffect(() => {
    unlock(lockProperties?.status === DoorLockEnum.Secured)
  }, [lockProperties?.status])

  const handleLock = (lock: boolean) => {
    unlock(lock)
    lockUnlockDoor(lock ? DoorLockEnum.Secured : DoorLockEnum.Unsecured)
  }

  return (
    <List>
      {devices?.map((item) => {
        const Icon = Icons[item.category] || MdAddHome
        return (
          <ListItem
            key={item._id}
            media={<Icon size='28' />}
            header='Name'
            title={item.name}
            after={
              <>
                {item.category === DeviceCategoryType.DOOR_LOCK && lockProperties && (
                  <ToggleLabel
                    className='k-color-brand-red '
                    checked={lock}
                    setToggle={() => handleLock(!lock)}
                    onLabel='LOCKED'
                    offLabel='UNLOCKED'
                  />
                )}
                <MdPlayArrow size='18' />
              </>
            }
          />
        )
      })}
    </List>
  )
}
