import { useQuery } from '@apollo/react-hooks'
import { HUB_SERVER_REPLAY_BY_ID } from '../graphql/queries/HubAccounts'
import { HubServerRelay } from '../types/inputTypes/HubAccount'
import sortBy from 'lodash/sortBy'
import { useAppConfig } from '../store/appConfig'

export const useHubServer = (deviceId?: string) => {
  const { hubAccountId, hubServers } = useAppConfig()

  return { hubServer: hubServers?.find((item) => item.hubId === deviceId) }
}

export const useHubServers = (hubAccountId?: string) => {
  const { data: hubServers, loading } = useQuery<{ getListEzloHubServerRelay: HubServerRelay[] }>(
    HUB_SERVER_REPLAY_BY_ID,
    {
      variables: { id: hubAccountId },
      fetchPolicy: 'network-only',
      skip: !hubAccountId
    }
  )

  return { hubServers: sortBy(hubServers?.getListEzloHubServerRelay, 'controllerStatus').reverse(), loading }
}
