import React, {useEffect} from 'react'
import { Block, Button, Card, Preloader } from 'konsta/react'
import { useHubServers } from '../../hooks/useHubServer'
import { useAppConfig } from '../../store/appConfig'
import { navTo } from '../../browserHistory'
import {useLocation} from "react-router";
import {getQueryObject} from "../../utils/helpers";

export function HubServers() {
  const location = useLocation()
  const params = getQueryObject(location.search)
  const hubId = params.get('hubId');
  const { hubAccountId, setAppConfig } = useAppConfig()
  const { hubServers, loading } = useHubServers(hubAccountId)
  const config: { [key: string]: { text: string; textColor: string; bgClass: string; isOnline: boolean } } = {
    '1': {
      text: 'Connection OK',
      textColor: 'text-green-500',
      bgClass: 'bg-green-500',
      isOnline: true
    },
    '0': {
      isOnline: false,
      text: 'Offline',
      bgClass: 'bg-red-500',
      textColor: 'text-red-500'
    }
  }

  useEffect(() => {
    if (hubId && !!hubServers?.find(item => item.hubId === hubId)) {
      handleSelectServer(hubId);
    }
  }, [loading, hubId]);

  const handleSelectServer = (deviceId: string) => {
    setAppConfig({ hubAccountId, deviceId, hubServers })
    navTo('/')
  }

  return (
    <Block className={`${loading ? 'text-center' : ''} mb-safe`}>
      {loading && <Preloader />}
      {hubServers?.map((item) => {
        const itemConfig = config[String(item.controllerStatus)]
        return (
          <Card
            key={item.hubId}
            footer={
              <>
                <div className='flex justify-start space-x-2 rtl:space-x-reverse'>
                  <Button
                    large
                    disabled={!itemConfig.isOnline}
                    onClick={() => handleSelectServer(item.hubId)}
                    className='text-white text-sm font-bold py-2 px-4 rounded'
                  >
                    Connect
                  </Button>
                </div>
              </>
            }
          >
            <div className='max-w-sm'>
              <div className='flex items-center justify-between mb-4'>
                <div className='flex items-center'>
                  <span className='flex h-3 w-3 mr-2'>
                    <span className={`relative inline-flex rounded-full h-3 w-3 ${itemConfig.bgClass}`}></span>
                  </span>
                  <p className='text-gray-800 text-sm font-semibold'>{item.hubId}</p>
                  <div></div>
                </div>
                <p className={`${itemConfig.textColor} text-xs italic`}>{itemConfig.text}</p>
              </div>
            </div>
          </Card>
        )
      })}
    </Block>
  )
}
