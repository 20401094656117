import {
  Block,
  Button,
  Link,
  Navbar,
  Page,
  Popup,
  ListInput,
  List,
  Preloader,
  Dialog,
  DialogButton
} from 'konsta/react'
import React, { useEffect, useRef, useState } from 'react'
import Countdown from '../../../components/CountDown'
import { Actions, CommandEnum } from '../../../constants/socket'
import { useHubSocketContext } from '../../../providers/hubsocket'
import { DEAD_DEVICE_ID, Device } from '../../../types/Ezlo/Device'

interface Props {
  open: boolean
  close?: () => void
}

export function ExcludeDevice({ close, open }: Props) {
  const [finish, setFinish] = useState(false)
  const { sendAction, action, sendMessage, onBroadcast } = useHubSocketContext()

  useEffect(() => {
    let off: any = undefined
    if (action === Actions.EXCLUDE_LOCK) {
      off = onBroadcast((data: any) => {
        if (data.data.msg_subclass === 'hub.device.removed') {
          setFinish(true)
        }
      })
    }
    return () => off && off()
  }, [action])

  const handleClose = () => {
    close && close()
  }

  const stopProcess = () => {
    sendMessage(
      CommandEnum.HubExtensionsPluginRun,
      {
        script: 'HUB:zwave/scripts/stop_exclude'
      },
      'Stop Exclude'
    )
    handleClose()
  }

  return (
    <>
      {' '}
      <Popup opened={open} onBackdropClick={() => handleClose()}>
        <Page>
          <Navbar
            title='Unpair Your Device'
            right={
              <Link navbar onClick={() => handleClose()}>
                x
              </Link>
            }
          />
          <p className='flex justify-center items-center p-2 bg-primary text-white text-sm'>Unpair Your Device</p>
          <Block className='space-y-4'>
            {open && (
              <>
                <p className='text-gray-800'>
                  To unpair a device effectively, stay within <b>3 meters</b> of the controller, and follow the{' '}
                  <b>user manual's inclusion</b> process.
                  <br />
                  <br />
                  If pairing fails after multiple attempts, the device may require a{' '}
                  <b>reset the device to factory settings</b> as it could be paired with another system.
                </p>
                <Block className='space-y-4'>
                  <Button large onClick={() => stopProcess()}>
                    Stop
                  </Button>
                </Block>
              </>
            )}
          </Block>
        </Page>
      </Popup>
      <Dialog
        opened={finish}
        title='Successful removed'
        content={<p>The device has been successfully unpaired</p>}
        buttons={
          <DialogButton
            onClick={() => {
              setFinish(false)
              handleClose()
            }}
          >
            Back to home
          </DialogButton>
        }
      />
    </>
  )
}
