import { App, Navbar, Page } from 'konsta/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import { ApolloProvider } from '@apollo/react-hooks'
import { apolloClient } from './services/graphql'
import { AuthenticationProvider } from './providers/authentication'
import { Router } from 'react-router-dom'
import Routes from './App'
import history from './browserHistory'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <AuthenticationProvider>
        <App theme='ios'>
          <Page>
            <Navbar title='NterNow Z-Wave Install Tool' />
            {/*<Home />*/}
            <Router history={history}>
              <Routes />
            </Router>
          </Page>
        </App>
      </AuthenticationProvider>
    </ApolloProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
