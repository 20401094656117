import {
  Block,
  Button,
  Link,
  Navbar,
  Page,
  Popup,
  ListInput,
  List,
  Preloader,
  Dialog,
  DialogButton
} from 'konsta/react'
import React, { useEffect, useRef, useState } from 'react'
import Countdown from '../../../components/CountDown'
import { Actions, CommandEnum } from '../../../constants/socket'
import { useHubSocketContext } from '../../../providers/hubsocket'
import { DEAD_DEVICE_ID, Device } from '../../../types/Ezlo/Device'

interface Props {
  open: boolean
  close?: () => void
}

export function ZwaveReset({ close, open }: Props) {
  const handleClose = () => {
    close && close()
  }

  return (
    <>
      {' '}
      <Popup opened={open} onBackdropClick={() => handleClose()}>
        <Page>
          <Navbar
            title='Zwave reset'
            right={
              <Link navbar onClick={() => handleClose()}>
                x
              </Link>
            }
          />
          <Block className='space-y-4'>
            {open && (
              <>
                <p className='text-gray-800'>Need to define</p>
              </>
            )}
          </Block>
        </Page>
      </Popup>
    </>
  )
}
