import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { HubServerRelay } from '../types/inputTypes/HubAccount'

export type AppConfigData = {
  deviceId?: string
  hubAccountId?: string
  hubServers?: HubServerRelay[]
}

type AppConfigMethod = {
  setAppConfig: (data: Partial<AppConfigData>) => void
}

type AppConfig = AppConfigData & AppConfigMethod

export const useAppConfig = create<AppConfig>()(
  persist(
    (set) => ({
      setAppConfig: (data) => {
        set((state) => ({ ...state, ...data }))
      }
    }),
    {
      name: 'app-config'
    }
  )
)
