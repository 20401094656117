import { Block, Button, Link, Navbar, Page, Popup, ListInput, List, Preloader } from 'konsta/react'
import React, { useEffect, useRef, useState } from 'react'
import Countdown from '../../../components/CountDown'
import { Actions, CommandEnum } from '../../../constants/socket'
import { useHubSocketContext } from '../../../providers/hubsocket'
import { DEAD_DEVICE_ID, Device } from '../../../types/Ezlo/Device'

interface Props {
  open: boolean
  close?: () => void
}

const STEP_TEXT: { [key: string]: string } = {
  '1': 'Pair Your Device',
  '2': 'Enter Device Specific Key',
  '3': 'Include Device',
  '4': 'Pairing Completed',
  '5': 'Pairing Failed'
}

export function AddLockDevice({ close, open }: Props) {
  const countdownRef = useRef<any>(null)
  const [retry, setRetry] = useState(false)
  const [step, setStep] = useState(1)
  const [deviceKey, setDeviceKey] = useState()
  const { sendAction, action, sendMessage, onBroadcast } = useHubSocketContext()
  const [includeProgress, setIncludeProgress] = useState<number>(0)
  const [addedDevice, setAddedDevice] = useState<Device>()

  useEffect(() => {
    let off: any = undefined
    if (action === Actions.ADD_LOCK) {
      off = onBroadcast((data: any) => {
        switch (data.data.result.event) {
          case 's2_select_authentication_modes':
            sendMessage(
              CommandEnum.HubExtensionsPluginRun,
              {
                script: 'HUB:zwave/scripts/set_authentication_mode',
                scriptParams: { modes: ['accessControl'] }
              },
              'S2 Auth Mode'
            )
            break
          case 's2_request_device_specific_key':
            setStep(2)
            break
          case 'include_device_progress':
            setStep((current) => {
              if (current > 3) {
                return current
              }
              setIncludeProgress(data.data.result.value)
              return 3
            })
            break
        }
        if (data.data.msg_subclass === 'hub.device.added') {
          const device = data.data.result as Device
          setAddedDevice(device)
          if (device.deviceTypeId === DEAD_DEVICE_ID) {
            setStep(5)
          } else {
            setStep(4)
          }
        }
      })
    }
    return () => off && off()
  }, [action])

  const handleClose = () => {
    close && close()
  }

  const retryAddLock = () => {
    setRetry(false)
    countdownRef.current.resetCountdown()
    sendAction(Actions.ADD_LOCK)
  }

  const submitKey = () => {
    sendMessage(
      CommandEnum.HubExtensionsPluginRun,
      {
        script: 'HUB:zwave/scripts/set_device_specific_key',
        scriptParams: { key: deviceKey }
      },
      'Set Device Key'
    )
    setStep(3)
  }

  const stopProcess = () => {
    sendMessage(
      CommandEnum.HubExtensionsPluginRun,
      {
        script: 'HUB:zwave/scripts/stop_include'
      },
      'Stop Include'
    )
    handleClose()
  }

  const step1 = () => (
    <>
      <p className='text-gray-800'>
        To pair the device effectively, stay within <b>3 meters</b> of the controller, and follow the{' '}
        <b>user manual's inclusion</b> process.
        <br />
        <br />
        If pairing fails after multiple attempts, the device may require a <b>reset</b> as it could be paired with
        another system.
      </p>
      <Countdown ref={countdownRef} initialTimeInSeconds={60} onTimeUp={() => setRetry(true)} />
      <Block className='space-x-4 flex'>
        <Button disabled={!retry} large onClick={() => retryAddLock()}>
          Retry
        </Button>
        <Button className='k-color-brand-red' large onClick={() => stopProcess()}>
          Stop
        </Button>
      </Block>
    </>
  )

  const step2 = () => (
    <>
      <p className='text-gray-800'>
        To pair the device effectively, stay within <b>3 meters</b> of the controller, and follow the{' '}
        <b>user manual's inclusion</b> process.
        <br />
        <br />
        If pairing fails after multiple attempts, the device may require a <b>reset</b> as it could be paired with
        another system.
      </p>
      <Block className='space-y-4'>
        <List strongIos insetIos>
          <ListInput type='text' placeholder='Enter Key' onChange={(e) => setDeviceKey(e.target.value)} />
        </List>
        <Button disabled={!deviceKey} large onClick={() => submitKey()}>
          Next
        </Button>
      </Block>
    </>
  )

  const step3 = () => (
    <>
      <p className='text-gray-800'>
        To pair the device effectively, stay within <b>3 meters</b> of the controller, and follow the{' '}
        <b>user manual's inclusion</b> process.
        <br />
        <br />
        If pairing fails after multiple attempts, the device may require a <b>reset</b> as it could be paired with
        another system.
      </p>
      <Block className='space-y-4 text-center text-green-500'>
        <Preloader /> <p className='!mt-0'>{includeProgress}%</p>
      </Block>
    </>
  )

  const step4 = () => (
    <>
      <Block className='space-y-4 text-center'>
        <p className='text-green-500'>Device successful added</p>
        <p className='text-gray-800'>Device name: {addedDevice?.name}</p>
        <Button className='k-color-brand-green' onClick={() => handleClose()}>
          Done
        </Button>
      </Block>
    </>
  )

  const step5 = () => (
    <>
      <Block className='space-y-4'>
        <p className='text-red-500 text-center'>Lock didn't pair correctly screen</p>
        <p>Trouble pairing lock to Z-Wave network. Follow these steps:</p>
        <ul>
          <li>1. Check lock pairing process.</li>
          <li>2. Perform Z-Wave exclude to remove previous pairings.</li>
          <li>3. Factory reset lock if needed.</li>
          <li>4. Retry Z-Wave pairing process.</li>
        </ul>
        Refer to lock manual or contact support for assistance.
        <Button className='k-color-brand-green' onClick={() => handleClose()}>
          Ok
        </Button>
      </Block>
    </>
  )

  return (
    <Popup opened={open} onBackdropClick={() => handleClose()}>
      <Page>
        <Navbar
          title='Pair Your Device'
          right={
            <Link navbar onClick={() => handleClose()}>
              x
            </Link>
          }
        />
        <p className='flex justify-center items-center p-2 bg-primary text-white text-sm'>
          Step {step}: {STEP_TEXT[String(step)]}
        </p>
        <Block className='space-y-4'>
          {open && (
            <>
              {step == 1 && step1()}
              {step == 2 && step2()}
              {step == 3 && step3()}
              {step == 4 && step4()}
              {step == 5 && step5()}
            </>
          )}
        </Block>
      </Page>
    </Popup>
  )
}
