import React from 'react'
import { Block, Preloader } from 'konsta/react'

interface Props {
  children: React.ReactNode
}
export function AuthenticationProvider({ children }: Props) {
  const isLoading = false

  if (isLoading) {
    return (
      <Block strong insetMaterial outlineIos className='text-center'>
        <Preloader />
      </Block>
    )
  }

  return <>{children}</>
}
