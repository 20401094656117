import { gql } from 'apollo-boost'

export const HUB_SERVER_REPLAY_BY_ID = gql`
  query ($id: String!) {
    getListEzloHubServerRelay(id: $id) {
      hubId
      serverRelay
      identity
      identitySignature
      controllerStatus
    }
  }
`
