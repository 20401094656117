import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { getQueryObject } from '../../utils/helpers'
import { setAuth } from '../../services/auth'
import { navTo } from '../../browserHistory'
import { Block } from 'konsta/react'
import { useAppConfig } from '../../store/appConfig'

export function Login() {
  const { setAppConfig } = useAppConfig()
  const location = useLocation()
  const params = getQueryObject(location.search)
  const token = params.get('token')
  const hubAccountId = params.get('hubAccountId')
  const hubId = params.get('hubId')

  useEffect(() => {
    if (token && hubAccountId) {
      setAppConfig({ hubAccountId })
      setAuth(token)
      navTo('/servers?hubId=' + hubId);
    }
  }, [token, hubAccountId, hubId])

  return <Block>Invalid token</Block>
}
