import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Home } from './pages/Home'
import { Login } from './pages/Login'
import NotFound from './pages/NotFound'
import { HubServers } from './pages/HubServers'

type Props = {}
const Routes: React.FC<Props> = () => (
  <Switch>
    <Route exact path='/login' component={Login} />
    <Route exact path='/' component={Home} />
    <Route exact path='/servers' component={HubServers} />
    {/*<Redirect exact from="/" to="/home" />*/}
    <Route component={NotFound} />
  </Switch>
)

export default Routes
