export enum ConnectStatus {
  NotConnect = 'NotConnect',
  Connecting = 'Connecting',
  Connected = 'Connected'
}

export enum EzloResponseMethod {
  Login = 'loginUserMios',
  Registered = 'registered'
}

export enum EzloResponseId {
  LoginUser = 'loginUser',
  Register = 'register',
  UIBroadcast = 'ui_broadcast'
}

export enum CommandEnum {
  HubDevicesList = 'hub.devices.list',
  HubItemValueSet = 'hub.item.value.set',
  HubItemsList = 'hub.items.list',
  HubReboot = 'hub.reboot',
  HubNetworkGet = 'hub.network.get',
  HubDeviceCheck = 'hub.device.check',
  DictionaryRemove = 'hub.item.dictionary.value.remove',
  DictionarySet = 'hub.item.dictionary.value.set',
  HubNetworkReconnect = 'hub.network.modem.try_connect',
  HubExtensionsPluginRun = 'hub.extensions.plugin.run'
}

export enum Actions {
  SEE_LOGS = 'see_logs',
  ADD_LOCK = 'add_lock',
  EXCLUDE_LOCK = 'exclude_lock',
  ZWAVE_RESET = 'zwave_reset',
  HUB_REBOOT = 'hub_reboot'
}
