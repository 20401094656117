import axios from './axios'
import * as storage from './storage'
import { AUTH_HEADER, AUTH_TOKEN, AUTH_USER } from '../constants/auth'

export const logOut = () => {
  storage.removeItem(AUTH_TOKEN)
  storage.removeItem(AUTH_USER)
  delete axios.defaults.headers.common[AUTH_HEADER]
}

export const setAuth = (token: string) => {
  storage.addItem(AUTH_TOKEN, token)
  axios.defaults.headers.common[AUTH_HEADER] = token
}

export const getAuthUser = () => storage.getItem(AUTH_USER)

export const isUserAuthorized = (): boolean => {
  const authToken = storage.getItem(AUTH_TOKEN)

  return Boolean(authToken)
}
