import { Chip, Link, List, ListItem, Navbar, Page, Popup } from 'konsta/react'
import React from 'react'
import { formatDate } from '../../../utils/date'
import { BroadcastMessage } from './BroadcastMessage'

interface Props {
  messages: any[]
  popupOpened: boolean
  setPopupOpened: (value: boolean) => void
}

export function BroadcastMessagePopup({ messages, popupOpened, setPopupOpened }: Props) {
  return (
    <Popup opened={popupOpened} onBackdropClick={() => setPopupOpened(false)}>
      <Page>
        <Navbar
          title='Events'
          right={
            <Link className='text-sm' navbar onClick={() => setPopupOpened(false)}>
              Close
            </Link>
          }
        />
        <BroadcastMessage />
      </Page>
    </Popup>
  )
}
